.App {
  text-align: center;
}
main {
  min-height: calc(100vh - 6rem);
}

/* body {
  -ms-overflow-style: none; /* IE and Edge */
/* scrollbar-width: none; Firefox */
/* } */
/* */

/* body::-webkit-scrollbar {
  display: none;
}  */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.toggle-checkbox:checked {
  right: 0;
}

/* .toggle-checkbox:checked + .toggle-label {
  @apply bg-green-500;
  background-color: #27ae60;
} */

input[type='date'] {
  display: block;

  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
}

/* react-checkbox-tree */
span.rct-node-icon {
  display: none;
}
.react-checkbox-tree label {
  display: flex;
}
